import { DataType } from '..';

const notFoundLabels = {
  emailUs: 'Email us at hello@thesimply.city to apply for a test account',
  waitlist: 'Join the waitlist',
};

const genericLabels = {
  contactUs: 'Contattaci',
  logout: 'Esci',
  month: 'Mese',
  na: 'N/D',
  add: '+ Aggiungi',
  terms: 'Terms',
  privacy: 'Privacy Policy',
};

const loginLabels = {
  signIn: 'Accedi',
  detail: 'Con il tuo account Google aziendale',
  new: 'Nuovo su SimplyCity?',
};

const dashboardLabels = {
  keywords: 'Parole chiave',
  toDo: 'Da fare',
  reviews: 'Reviews',
  businessLocation: 'Posizione aziendale',
};

const keywordLabels = {
  high: 'Alto',
  medium: 'Medio',
  low: 'Basso',
  delete: 'Parola Chiave Elimina',
  typeKeyword: 'Inserisci parola chiave',
  overallStrength: 'Forza del profilo complessiva',
  reviewStats: 'Statistiche delle recensioni',
  rankings: 'Classifiche delle parole chiave',
  description:
    "Le parole chiave per l'indicizzazione locale SEO di un luogo possono variare a seconda della posizione specifica, delle offerte e del pubblico di riferimento. Queste sono alcune delle parole chiave rilevanti per la tua attività. Tenere traccia dei progressi è fondamentale nel nostro sistema 👍",
  processingKeywords: 'Processing Keywords',
  researchKeywords:
    'The keywords for your businesses are still being researched by the simiplicty team',
};

const statsLabels = {
  movementInfo: '(dal primo accesso)',
  competitorsText: (count: number) => `Tra ${count} concorrenti`,
};

const reviewLabels = {
  title: 'Reply Recent Reviews',
  stars: 'Stars',
  generateReply: 'Generate Reply',
};

const todoLabels = {
  edit: 'Modifica elemento',
  delete: 'Elimina elemento',
  progress: 'Il progresso della tua lista di controllo',
  essentialInfo: 'Informazioni essenziali',
  mustHaves: 'Must Have di SimplyCity',
  addChecklist: 'Inserisci voce di lista di controllo',
};

const alertLabels = {
  signout: 'Are you sure you want to sign out?',
  deleteToDo: (title: DataType) => `Sei sicuro di voler eliminare "${title}"?`,
  deleteKeyword: (keyword: DataType) =>
    `Sei sicuro di voler eliminare la parola chiave "${keyword}"?`,
};

const italianTranslations = {
  reviewLabels,
  notFoundLabels,
  alertLabels,
  statsLabels,
  genericLabels,
  loginLabels,
  dashboardLabels,
  keywordLabels,
  todoLabels,
};

export default italianTranslations;
